<template>
  <div>
    <section>
      <article class="home_article_one">
        <el-carousel
          height="90vh"
          direction="vertical"
          :interval="5000"
          arrow="always"
          :autoplay="true"
        >

          <el-carousel-item>
            <div class="carousel_description">
              <div class="images">
                <div>
                  <img
                    src="https://images.pexels.com/photos/325260/pexels-photo-325260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/325260/pexels-photo-325260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/117139/pexels-photo-117139.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="../../assets/images/stock.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/632452/pexels-photo-632452.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div class="image-description">
                <div class="container">
                  <div>
                    <p class="locus_word_style">
                      <small>Ihamba Adventures</small>
                    </p>
                    <p>The Birders Experience.</p>
                    <button
                      class="btn btn-md moreBtn"
                      @click="$router.push({path: '/safari-packages'})"
                    >
                      Explore more
                      <i class="el-icon-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>

          <el-carousel-item>
            <div class="carousel_description">
              <div class="images">
                <div>
                  <img
                    src="https://images.pexels.com/photos/7280783/pexels-photo-7280783.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/247376/pexels-photo-247376.jpeg?auto=compress&cs=tinysrgb&w=600"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/47547/squirrel-animal-cute-rodents-47547.jpeg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/70080/elephant-africa-african-elephant-kenya-70080.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/7018141/pexels-photo-7018141.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div class="image-description">
                <div class="container">
                  <div>
                    <p class="locus_word_style">
                      <small>Ihamba Adventures</small>
                    </p>
                    <p>The Best of Africa's Wildlife Game.</p>
                    <button
                      class="btn btn-md moreBtn"
                      @click="$router.push({path: '/safari-packages'})"
                    >
                      Explore more
                      <i class="el-icon-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>

          <el-carousel-item>
            <div class="carousel_description">
              <div class="images">
                <div>
                  <img
                    src="https://images.pexels.com/photos/6746533/pexels-photo-6746533.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/1238275/pexels-photo-1238275.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/6233548/pexels-photo-6233548.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/51198/gorilla-monkey-view-grim-51198.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/6194629/pexels-photo-6194629.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div class="image-description">
                <div class="container">
                  <p class="locus_word_style">
                    <small>Ihamba Adventures</small>
                  </p>
                  <p>The Best of Africa's Gorilla Trekking Game.</p>
                  <button
                    class="btn btn-md moreBtn"
                    @click="$router.push({path: '/safari-packages'})"
                  >
                    Explore more
                    <i class="el-icon-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </el-carousel-item>

          <el-carousel-item>
            <div class="carousel_description">
              <div class="images">
                <div>
                  <img
                    src="https://images.pexels.com/photos/667200/pexels-photo-667200.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div>
                  <div>
                    <img
                      src="https://images.pexels.com/photos/2170387/pexels-photo-2170387.jpeg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Matoke.JPG/1200px-Matoke.JPG"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://ucarecdn.com/8e3e6991-6848-4880-b78a-ff600e45f97c/-/crop/1080x720/100,0/-/preview/630x420/"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/The_Karamojong_or_Karimojong%2C_an_ethnic_group_of_agro-pastoral_herders_living_mainly_in_the_north-east_of_Uganda..jpg/1200px-The_Karamojong_or_Karimojong%2C_an_ethnic_group_of_agro-pastoral_herders_living_mainly_in_the_north-east_of_Uganda..jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div class="image-description">
                <div class="container">
                  <p class="locus_word_style">
                    <small>Ihamba Adventures</small>
                  </p>
                  <p>The Best of Africa's Culture Game.</p>
                  <button
                    class="btn btn-md moreBtn"
                    @click="$router.push({path: '/safari-packages'})"
                  >
                    Explore more
                    <i class="el-icon-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </el-carousel-item>

        </el-carousel>
        <div class="home_article_one_description">
          <div
            class="row"
            style="width: 100%"
          >
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="text-center">
                <h1>Explore Africa, Eco-friendly Adventures</h1>
              </div>
              <div class="article_form">
                <el-form
                  :model="safariPackageForm"
                  ref="safariPackageForm"
                  class="demo-safariPackageForm2"
                >
                  <div class="introductory_form">
                    <div>
                      <el-form-item style="width: 100%;">
                        <el-select
                          v-model="safariPackageForm.destination_country"
                          placeholder="Enter Your Dream Destination"
                          filterable=""
                          size="large"
                        >
                          <el-option
                            v-for="country in destinations"
                            :key="country.id"
                            :label="country.country_title"
                            :value="country.country_title"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item class="text-center w-100">
                        <el-button
                          size="large"
                          style="width: 100%; border: 2px solid #C1AA7F; background-color: #C1AA7F; color: white;"
                          @click="$router.push({ path: '/safari-packages', query: { whereTo: `${safariPackageForm.destination_country}`, startDate: `${safariPackageForm.check_in_date}` } })"
                        >SEARCH</el-button>
                      </el-form-item>
                    </div>
                  </div>

                </el-form>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      destinations: [],

      safariPackageForm: {
        title: "",
        check_in_date: "",
        number_adult_guest: "",
        number_children_guest: "",
      },
    };
  },

  mounted() {
    this.getDestinations();
  },

  methods: {
    async getDestinations() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/country/get_all`
        );
        if (request.data.success) {
          this.destinations = request.data.countries;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        return;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.home_article_one {
  position: relative;
  height: 90vh;
}
.home_article_one img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}
.home_article_one_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5)
  );
  z-index: 9;
  padding-top: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.home_article_one_description h1 {
  color: white;
  font-size: 2.5em;
  font-weight: 600;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}
.home_article_one_description p {
  color: white;
}
.article_form {
  border-radius: 5px;
  padding: 10px 10px 20px 10px;
}
.introductory_form {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2px;
}
.introductory_form > div {
  display: flex;
  align-items: flex-end;
}

.introductory_form > div:first-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .home_article_one {
    height: 90vh;
  }
  .home_article_one img {
    height: 90vh;
  }
  .home_article_one_description h1 {
    font-size: 1.5em;
  }

  .introductory_form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .home_article_one {
    height: 90vh;
  }
  .home_article_one img {
    height: 90vh;
  }
  .home_article_one_description h1 {
    font-size: 1.5em;
  }

  .introductory_form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
  }
}
</style>
<style>
.home_article_one .el-select .el-input__inner {
  height: 60px;
  width: 400px;
}
.home_article_one .el-button {
  height: 60px;
}
.article_form .el-form-item {
  margin-bottom: 0px;
}

.article_form .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
  color: white !important;
}
.article_form label {
  display: inline-block;
  margin-bottom: 0px;
  color: white !important;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .home_article_one .el-select .el-input__inner {
    height: 60px;
    width: 360px;
    margin-bottom: -2px !important;
  }
  .home_article_one .el-button {
    height: 60px;
    width: 360px !important;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .home_article_one .el-select .el-input__inner {
    height: 60px;
    width: 360px;
    margin-bottom: -2px !important;
  }
  .home_article_one .el-button {
    height: 60px;
    width: 360px !important;
  }
}
</style>